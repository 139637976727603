import React, { useState, useEffect } from "react"
import styled from "styled-components"

import SvgTwitterLogo from "../../../images/projects/ens-time-keepers/twitter-logo.svg"

const Container = styled.div`
    padding: 1em;
    margin: 1em 0;
    font-weight: bolder;

    display: flex;
    x-justify-content: space-around;
    x-align-items: stretch;
`
const Stats = styled.div`
    width: 80%;
    flex: 0 0 80%;
`
const Twitter = styled.div`
    width: 20%;
    flex: 0 0 20%;
    text-align: right;
`
const TwitterLogo = styled.img`
    height: 100px;
    width: 100px;

    &:hover {
        cursor: pointer;
    }
`

const HourHouseStats = () => {
    const ENDPOINT = process.env.NODE_ENV !== 'production' ? 'http://localhost:8888/' : 'https://api.harrydenley.com/'
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentMinute, setCurrentMinute] = useState<number>((new Date().getUTCMinutes()));

    const [totalTweets, setTotalTweets] = useState<number>(0);
    const [lastTweet, setLastTweet] = useState<any>();

    useEffect(() => {
        setIsLoading(true);
        getTweetStats();
        setIsLoading(false);

        const minuteCounter = setInterval(() => {
            setCurrentMinute((new Date().getUTCMinutes()));
        }, 45000)

            return () => clearInterval(minuteCounter);
      }, [currentMinute]);

    const format0xAddress = (addr: string) => {
        return [addr.substring(0, 6), addr.substring(36, 42)].join(`...`) 
    }

    const getTweetStats = async() => {
        const res = await fetch(`${ENDPOINT}/ethereum/ens-time-keepers/stats`)
            .then(response => response.json())
            .then(data => {
                return data.data
            })
            .catch((e) => {
                console.log(e)
            })

        if(res) {
            setTotalTweets(res.total_tweets)
            setLastTweet(res.last_tweet_by)
        }

        setIsLoading(false)
    }

    return(
        <Container>
            <Stats>
                Total Tweets: {totalTweets} <br />
                Last Tweet: {lastTweet && lastTweet.ens_name} ({lastTweet && format0xAddress(lastTweet.address)})
            </Stats>
            <Twitter>
                <a href="https://twitter.com/EnsTimeKeepers" target="_blank" rel="nofollow noreferer">
                    <TwitterLogo src={SvgTwitterLogo} />
                </a>
            </Twitter>
        </Container>
    )
}

export default HourHouseStats